import React from 'react';

import react from "../assets/react.png";

export default () => {
    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <img className="img img-circle float-right" src={react} style={{height: "200px"}}/>
                    <p>
                        Our goal is to give speakers a platform to share their discoveries and ideas.
                    </p>
                    <p>
                        We are a free monthly meetup that focuses on UI engineering with Facebook's React Javascript framework.
                        Each meetup consists of networking and cutting-edge technical talks from local speakers.
                    </p>

                    <p style={{fontSize: "150%"}}>
                        <strong>We'll see you on August 22nd at Fresh Consulting!</strong>
                    </p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-peach">Tickets</h1>
                    <p>
                        To register for tickets for our next event, please visit our <a href="https://www.meetup.com/seattle-react-js/" className="text-info">meetup.com</a> site.<br />
                        Note: seats are limited, so please verify your availability before registering.
                    </p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-peach">Speaking</h1>
                    <p>Interested at speaking at our meetup?</p>
                    <p>We are always looking for speakers, and to help those who want to get into speaking.</p>
                    <ul>
                        <li><strong className="text-info">Projects</strong> things you've seen, built, or want to build</li>
                        <li><strong className="text-info">Tools</strong> what do you use to get the job done?</li>
                        <li><strong className="text-info">Frameworks</strong> what's going on in the world of React?</li>
                    </ul>
                    <p>If you're interested in speaking please e-mail: <a href="mailto:twalkerweb@gmail.com">twalkerweb@gmail.com</a>.</p>
                </div>
            </div>
            <hr />
            <div className="row">
                <div className="col-md-12">
                    <h1 className="text-peach">Hosting</h1>
                    <p>We're always looking for new hosts, if you can support the following:</p>
                    <ul>
                        <li>Presenter station with HDMI</li>
                        <li>Space for 80 to 200 individuals</li>
                    </ul>
                    <p>If you're interested in speaking please e-mail: <a href="mailto:seattlereactjs@gmail.com">seattlereactjs@gmail.com</a>.</p>
                </div>
            </div>
        </React.Fragment>
    )
}